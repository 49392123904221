/**
 * @file 入口文件
 * @author jianghaoran01
 */
import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import PrimaryLayout from './layout/primary-layout';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import './App.css';

moment.locale('zh-cn');

function App() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={zhCN}>
          <PrimaryLayout />
        </ConfigProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
