const localeValues = {
    pageHeader: {
        title: 'PIKA'
    },
    login: {
        title: '在线报名系统',
        username: '账号',
        password: '密码',
        login: '登录'
    },
    menu:{
        home:'发布公告',
        exams:'报名管理',
        examsEnterprise: '企业报名管理',
        examsPersonal: '个人报名管理',
        examPlaces:'考点列表',
        examRooms:'考场编排',
        examMaintain: '考点维护',
        examTypes:'城市类别列表',
        examCenterManagement: '报名考点管理',
        registerList: '注册考生',
        admissionCard: '准考证生成',
        companyManagement:'企业管理',
        emailManagement:'邮件管理',
        candidateEmail: '考生端邮件',
        configureOutbox: '配置发件箱',
        companyList:'企业客户列表',
        contractList:'企业合同列表',
        contactList:'企业联系人列表',
        typeManagement: '考试类型管理',
        roomArrangement: '考场编排',
        printData: '打印考试资料',
        printArrangement: '考点安排表',
        printNameList: '考生名单表',
        printSeating: '座位表',
    },
    footer:{
        privacy:'京ICP备2022015257号-1'
    },
    home:{
        title:'公告列表',
        add:'新增公告'
    },
    noticeMain:{
        title:'新增公告',
        editTitle:'编辑公告',
        head:'文章名称',
        type:'文章类型',
        content:'文章内容',
        time:'发布时间',
        submit:'发布',
        cancel:'取消'
    },
    registerMain:{
        title:'新增报名',
        editTitle:'编辑报名',
        head:'考试名称',
        registerType:'报考类型',
        status:'考试状态',
        type:'考试类型',
        signTime:'报名时间',
        time:'考试日期',
        enterTime:'入场时间',
        examStartTime:'考试开始时间',
        examEndTime:'考试结束时间',
        area:'报考区域',
        submit:'创建',
        editSubmit:'确认',
        cancel:'取消'
    },
    examsList:{
        title:'考试列表',
        add:'新增报名',
    },
    exams:{
        title:'考试列表',
        add:'新增报名',
        modalTitle:'新增报名',
        modalEditTitle:'编辑报名',
        modalName:'考试城市类别名称',
        modalType:'考点类型',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    examTypes:{
        title:'考试城市类别列表',
        add:'新增城市类别',
        modalTitle:'新增考试城市类别',
        modalEditTitle:'编辑考试城市类别',
        modalName:'考试城市类别名称',
        modalType:'考点类型',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    examrooms:{
        title:'考场列表',
        add:'新增考场',
        modalTitle:'新增考场',
        modalEditTitle:'编辑考场',
        modalName:'考场名称',
        modalNo:'考场编号',
        modalRoomNo:'房间号码',
        modalSits:'座位数',
        modalDanger:'是否高危',
        modalApt:'建筑物名',
        modalAddress:'备注地址',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    examroommain:{
        title:'新增报名',
        editTitle:'编辑报名',
        name:'考场编排',
        submit:'确定',
        editSubmit:'确定',
        cancel:'取消',
        enterTime:'入场时间',
        startTime:'考试开始时间'
    },
    areas:{
        title:'城市列表',
        add:'新增城市',
        modalTitle:'新增城市',
        modalEditTitle:'编辑城市',
        modalName:'省/直辖市名称',
        modalSecondName:'城市',
        modalAreaName:'城市名称',
        modalAreaCode:'城市代码',
        modalAreaCap:'容量',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    places:{
        title:'考点列表',
        add:'新增考点 ',
        modalTitle:'新增考点',
        modalEditTitle:'编辑考点 ',
        modalName:'考点名称',
        modalType:'所属城市类别',
        modalCity:'考点城市',
        modalAddress:'考点地址',
        modalContact:'联系人',
        modalPhone:'联系电话',
        modalEmail:'电子邮箱',
        modalConfirm:'确认',
        modalCancel:'取消'
    },
    students:{
        buttonConfig:'准考证配置',
        buttonDownload:'报名考生下载',
        buttonAdd:'添加考生',
        modalTitle:'准考证配置',
        modalEditTitle:'准考证配置',
        modalName:'发布时间',
        modalType:'准考证附件',
        modalConfirm:'确定',
        modalCancel:'取消',
        modalClose:'关闭'
    },
    arrangeList:{
        title:'考试列表',
    },
    registerList: {
        title: '注册考生列表',
        export: '导出考生'
    },
    registerEdit: {
        title: '编辑考生',
    },
    admissionCard: {
        title: '准考证生成'
    },
    companyList: {
        title: '企业客户列表',
        export: '新增企业客户'
    },
    contractList: {
        title: '企业合同列表',
        export: '新增企业合同'
    },
    contactList: {
        title: '企业联系人列表',
        add: '新增企业联系人'
    },
    candidateEmail: {
        title: '考生端邮件',
        add: '新增邮件模板'
    },
    configureOutbox: {
        title: '配置发件箱',
        add: '新增发件邮箱'
    },
    scoreList: {
        title: '成绩列表',
        publish:'发布成绩',
        upload: '上传成绩'
    },
    examTypeManagement: {
        title: '考试类型管理',
        addExamType: '新增考试类型'
    },
    examCenterManagement: {
        title: '考场列表',
        addExamCenter: '新增考场',
        examCenterName: '考场名称',
        examCenterCode: '考场编号',
        roomNum: '房间号码',
        seatsNum: '座位数',
        buildingName: '建筑物名',
        remarkAddress: '备注地址',
        modalTitle: '新增考场',
        modalEditTitle: '编辑考场',
        modalCancel: '取消',
        modalConfirm: '确认',
        tips: '请输入',
    },
    roomArrangement: {

    },
    printArrangement: {
        title: '打印考点安排表',
        examType: '考试类型',
        examTime: '考试时间',
        download1: '下载考点安排表',
        download2: '下载考生名单表',
        download3: '下载座位表',
        download4: '下载考场报告',
        examCity: '考试城市',
        examPoint: '考试考点'
    },
    printNameList: {
        title: '打印考生名单表',
        examType: '考试类型',
        examTime: '考试时间',
        download: '下载考生名单表'
    },
    printSeating: {
        title: '打印座位表',
        chooseExam: '选择考试',
        download: '下载座位表'
    }
}

export default localeValues;