/*
 * @Author: jianghaoran
 * @Date: 2023-05-19 14:03:04
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-22 11:16:49
 * @FilePath: \toeic-frontend-management\src\configure\routes\dashboard.js
 * @Description: 路由配置文件-dashboard
 */

import React from 'react';
import { Redirect } from 'react-router';

const routers = [
    {
        key: '/enterprise-registration/list',
        path: '/enterprise-registration/list',
        exact: true,
        component: React.lazy(() => import('@views/enterprise-registration/list'))
    },
    {
        key: '/enterprise-registration/list',
        path: '/enterprise-registration/edit-normal-registration/:examId?',
        exact: true,
        component: React.lazy(() => import('@views/enterprise-registration/edit-normal-registration'))
    },
    {
        key: '/enterprise-registration/list',
        path: '/enterprise-registration/edit-mock-exam/:examId?',
        exact: true,
        component: React.lazy(() => import('@/views/enterprise-registration/edit-mock-exam'))
    },
    {
        key: '/exams-personal',
        path: '/exams-personal',
        exact: true,
        component: React.lazy(() => import('@views/exams'))
    },
    {
        key: '/exams-personal',
        path: '/students/:id',
        exact: true,
        component: React.lazy(() => import('@views/studentsList'))
    },
    {
        key: '/enterprise-registration/list',
        path: '/enterprise-students/:id',
        exact: true,
        component: React.lazy(() => import('@views/enterprise-student-list'))
    },
    {
        key: '/notices',
        path: '/notices',
        exact: true,
        component: React.lazy(() => import('@views/dashboard'))
    },
    {
        key: '/notices',
        path: '/noticemain/:id',
        exact: true,
        component: React.lazy(() => import('@views/noticeMain'))
    },
    {
        key: '/notices',
        path: '/noticemain',
        exact: true,
        component: React.lazy(() => import('@views/noticeMain'))
    },
    {
        key: '/registerMain',
        path: '/registerMain/:id',
        exact: true,
        component: React.lazy(() => import('@views/registerMain'))
    },
    {
        key: '/registerMain',
        path: '/registerMain',
        exact: true,
        component: React.lazy(() => import('@views/registerMain'))
    },
    {
        key: '/examtypes',
        path: '/examtypes',
        exact: true,
        component: React.lazy(() => import('@views/examType'))
    },
    {
        key: '/examtypes',
        path: '/examareas/:id',
        exact: true,
        component: React.lazy(() => import('@views/examAreas'))
    },
    {
        key: '/account-register',
        path: '/account-register',
        exact: true,
        component: React.lazy(() => import('@views/account-register/list'))
    },
    {
        key: '/account-register',
        path: '/account-register/:id',
        exact: true,
        component: React.lazy(() => import('@views/account-register/edit'))
    },
    {
        path: '/room-arrangement',
        exact: true,
        component: React.lazy(() => import('@views/roomArrangement'))
    },
    {
        key: '/admission-card',
        path: '/admission-card',
        exact: true,
        component: React.lazy(() => import('@views/admission-card'))
    },
    {
        key: '/examplaces',
        path: '/examplaces',
        exact: true,
        component: React.lazy(() => import('@views/examPlaces'))
    },
    // {
    //     path: '/exam-center-management',
    //     exact: true,
    //     component: React.lazy(() => import('@views/examCenterManagement'))
    // },
    {
        key: '/examplaces',
        path: '/examrooms/:id',
        exact: true,
        component: React.lazy(() => import('@views/examRooms'))
    },
    {
        path: '/examroommain',
        exact: true,
        component: React.lazy(() => import('@views/examRoomMain'))
    },
    {
        key: '/arrangelist',
        path: '/arrangelist',
        exact: true,
        component: React.lazy(() => import('@views/arrangeList'))
    },
    {
        key: '/arrangelist',
        path: '/arrangecities/:id',
        exact: true,
        component: React.lazy(() => import('@views/arrangeCities'))
    },
    {
        key: '/arrangelist',
        path: '/examroommain/:exam_id/:city_id',
        exact: true,
        component: React.lazy(() => import('@views/examRoomMain'))
    },
    {
        key: '/type-management',
        path: '/type-management',
        exact: true,
        component: React.lazy(() => import('@views/typeManagement'))
    },
    {
        key: '/printData',
        path: '/printData',
        exact: true,
        component: React.lazy(() => import('@views/printArrangement'))
    },
    {
        key: '/printNameList',
        path: '/printNameList',
        exact: true,
        component: React.lazy(() => import('@views/printNameList'))
    },
    {
        key: '/printSeating',
        path: '/printSeating',
        exact: true,
        component: React.lazy(() => import('@views/printSeating'))
    },
    {
        key: '/companylist',
        path: '/companylist',
        exact: true,
        component: React.lazy(() => import('@views/company-management/company-list'))
    },
    {
        key: '/contractlist',
        path: '/contractlist',
        exact: true,
        component: React.lazy(() => import('@views/company-management/contract-list'))
    },
    {
        key: '/contactlist',
        path: '/contactlist',
        exact: true,
        component: React.lazy(() => import('@views/company-management/contact-list'))
    },
    {
        key: '/candidateEmail',
        path: '/candidateEmail',
        exact: true,
        component: React.lazy(() => import('@views/email-management/candidate-email'))
    },
    {
        key: '/configureOutbox',
        path: '/configureOutbox',
        exact: true,
        component: React.lazy(() => import('@views/email-management/configure-outbox'))
    },
    {
        key: '/scorelist',
        path: '/scorelist/:examId?',
        exact: true,
        component: React.lazy(() => import('@/views/score-list'))
    },
];

export default routers;
