
import React from 'react';
import userRoutes from './user';
import dashboardRoutes from './dashboard';
import { Redirect } from 'react-router';

const routers =  [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/enterprise-registration/list' />
    },
    ...userRoutes,
    ...dashboardRoutes,
    {
        path: '*',
        cache: true,
        component: React.lazy(() => import('@views/no-found'))
    }
];

export default routers;
