/*
 * @Author: JC 13478707150@163.com
 * @Date: 2023-05-19 14:09:12
 * @LastEditors: WIN-J7OL7MK489U\EDY 13478707150@163.com
 * @LastEditTime: 2023-08-04 10:19:40
 * @FilePath: \toeic-frontend-management\src\configure\layout\index.js
 * @Description: 菜单配置文件
 */

import React from 'react';

import { CopyOutlined, UserOutlined } from '@ant-design/icons';
import menu1 from '@/assets/menu1.png';
import menu2 from '@/assets/menu2.png';

export default [
    {
        key: '/exams',
        localeName: 'exams',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />,
        children: [
            {
                key: '/enterprise-registration/list',
                localeName: 'examsEnterprise',
                path: '/enterprise-registration/list'
            },
            {
                key: '/exams-personal',
                localeName: 'examsPersonal',
                path: '/exams-personal'
            }
        ]
    },
    {
        key: '/notices',
        path: '/notices',
        localeName: 'home',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu1} />
    },
    // {
    //     key: '/examplaces',
    //     path: '/examplaces',
    //     localeName: 'examPlaces',
    //     icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
    // },
    {
        key: '/account-register',
        path: '/account-register',
        localeName: 'registerList',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
    },
    {
        key: '/arrangelist',
        path: '/arrangelist',
        localeName: 'roomArrangement',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
    },
    {
        key: '/admission-card',
        path: '/admission-card',
        localeName: 'admissionCard',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
    },
    {
        key: '/manageExamPlaces',
        localeName: 'examMaintain',
        icon: <CopyOutlined style={{ color: '#1573FF' }} />,
        children: [
            {
                key: '/examtypes',
                localeName: 'examTypes',
                path: '/examtypes',
                icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
            },
            {
                key: '/examplaces',
                localeName: 'examPlaces',
                path: '/examplaces',
                icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
            },
            // {
            //     key: '/exam-center-management',
            //     localeName: 'examCenterManagement',
            //     path: '/exam-center-management',
            //     icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
            // }
        ]
    },
    {
        key: '/printData',
        localeName: 'printData',
        path: '/printData',
        icon: <CopyOutlined style={{ color: '#1573FF' }} />,
    },
    {
        key: '/manageCompany',
        localeName: 'companyManagement',
        icon: <UserOutlined style={{ color: '#1573FF' }} />,
        children: [
            {
                key: '/companylist',
                localeName: 'companyList',
                path: '/companylist',
                icon: <div className={'anticon'} style={{ width: '14px', height: '16px' ,marginLeft:'-24px'}}  />
            },
            {
                key: '/contractlist',
                localeName: 'contractList',
                path: '/contractlist',
                icon: <div className={'anticon'} style={{ width: '14px', height: '16px',marginLeft:'-24px' }}  />
            },
            {
                key: '/contactlist',
                localeName: 'contactList',
                path: '/contactlist',
                icon: <div className={'anticon'} style={{ width: '14px', height: '16px',marginLeft:'-24px' }}  />
            }
        ]
    },
    {
        key: '/emailManagement',
        localeName: 'emailManagement',
        icon: <UserOutlined style={{ color: '#1573FF' }} />,
        children: [
            {
                key: '/candidateEmail',
                localeName: 'candidateEmail',
                path: '/candidateEmail',
                icon: <div className={'anticon'} style={{ width: '14px', height: '16px' ,marginLeft:'-24px'}}  />
            },
            {
                key: '/configureOutbox',
                localeName: 'configureOutbox',
                path: '/configureOutbox',
                icon: <div className={'anticon'} style={{ width: '14px', height: '16px',marginLeft:'-24px' }}  />
            }
        ]
    },
    {
        key: '/type-management',
        path: '/type-management',
        localeName: 'typeManagement',
        icon: <img className={'anticon'} style={{ width: '14px', height: '16px' }} src={menu2} />
    },
];