/**
 * @file 页面的头部
 * @author jianghaoran01
 */
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Menu, Dropdown, notification} from 'antd';
import {user as userSlice} from '@store/user.slice.js';
import {API_SERVER, post} from '@request/service.js';
import {useLocaleReceiver} from '@local/locale-receiver';
import './index.less';
import logoTop from '@assets/logoTop.png'
import {QuestionCircleOutlined, UserOutlined } from '@ant-design/icons'

const PREFIX = 'app-header';

const AppHeader = props => {
    const history = useHistory();
    const userStore = useSelector(userSlice);
    const [pageHeaderLocal] = useLocaleReceiver('pageHeader');

    const onStorageCB = e => {
        console.log('storage change::::', e);
        if (!localStorage.getItem('author-token')) {
            history.push('/login');
        }
    };

    // 登出
    const logout = async () => {
        // const path = `${API_SERVER}/auth-service/api/logout`;
        // // return new EventSourcePolyfill(path);
        // const data = await post(path);
        // if (data.code === 200) {
        //     localStorage.removeItem('author-token');
        // } else {
        //     notification.error({message: data?.data?.message, duration: 5});
        // }
        localStorage.removeItem('author-token');
        history.push('/login');
    };

    const menuClick = ({key}) => {
        if (key == 1) {
            logout();
        }
    };

    const menu = (
        <Menu onClick={menuClick}>
            <Menu.Item key={1}>登出</Menu.Item>
        </Menu>
    );

    // 监听storage事件变化
    useEffect(() => {
        window.addEventListener('storage', onStorageCB);
        return () => {
            window.removeEventListener('storage', onStorageCB);
        };
    }, []);

    if (!localStorage.getItem('author-token')) {
        history.push('/login');
    }

    return (
        <div className={PREFIX}>
            <div className={`${PREFIX}-content`}>
                <div className={`${PREFIX}-left`}>
                    {/*<img src={logoTop} />*/}
                    在线报名系统后台
                </div>
                <Dropdown overlay={menu}>
                    <div className={`${PREFIX}-right`}>
                        <QuestionCircleOutlined style={{color:'#FFFFFF',margin:'0 24px 0 0', fontSize:'16px',verticalAlign:'middle'}}/>
                        <div className={'occupy'}> <UserOutlined style={{margin:'0 0 4px 4px', fontSize:'16px',verticalAlign:'middle'}}/>
                        </div>
                        {userStore?.detail?.name}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

export default AppHeader;
