/*
 * @Author: jianghaoran01
 * @Date: 2023-05-19 14:03:04
 * @LastEditors: JC 13478707150@163.com
 * @LastEditTime: 2023-05-19 16:32:44
 * @FilePath: \toeic-frontend-management\src\layout\layout-main\index.jsx
 * @Description: 布局主体
 */

import React, { Suspense } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routes from '@/configure/routes';

import { Layout, Spin } from 'antd';

import './index.less';

const Loading = () => <Spin size='large' style={{margin: '32px auto'}}></Spin>;

const LayoutMain = () => {

    return (
        <Layout className="main-area">
            <Switch>
                <Suspense fallback={<Loading />}>
                    {renderRoutes(routes)}
                </Suspense>
            </Switch>
        </Layout>
    );
};

export default withRouter(LayoutMain);