/**
 * @file 页面标题
 * @author jianghaoran
 */

import React, { useMemo, useState, useEffect } from 'react';
import { Menu } from 'antd';

import { useLocaleReceiver } from '@local/locale-receiver';
import { Link, useRouteMatch } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';

import layoutConfig from '@/configure/layout';
import routes from '@/configure/routes';

import './index.less';

const PREFIX = "page-menu";

const getMenuItems = (items = [], menuLocale) => {
    return items.map(({ path, localeName, children, ...args }) => {
        const menuItem = {
            ...args,
            label: !!children ? <a>{menuLocale[localeName]}</a> : <Link to={path}>{menuLocale[localeName]}</Link>
        };
        if (!!children) {
            menuItem.children = getMenuItems(children, menuLocale);
        }
        return menuItem;
    })
};

export default () => {
    const [menuLocale] = useLocaleReceiver('menu');
    const [selectedKey, setSelectedKey] = useState('');

    const match = useRouteMatch();

    const items = useMemo(() => getMenuItems(layoutConfig, menuLocale), []);

    useEffect(() => {
        const pathname = window.location.pathname;
        const currRoute = matchRoutes(routes, pathname)?.[0];
        setSelectedKey(currRoute?.route?.key);
    }, [match]);

    return (
        <Menu
            className={PREFIX}
            mode="inline"
            items={items}
            selectedKeys={[selectedKey]}
            inlineIndent={24}
        />
    )
}